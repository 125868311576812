.banner-home {
  position: absolute;
  z-index: 1030;
  top: 0;
  left: 0;
  right: 0;
  .navbar-default .navbar-toggle .icon-bar { background-color: #fff; }
}

body.page.contact {
  .navbar-page-title { display: none!important; }
}

@media (min-width: $screen-lg) {
  .contact-col { padding-right: 70px!important; }
}

.hero-section {
  height: 100vh;
  text-align: center;
  position: relative;
  cursor: pointer;
  color: #fff;
  background: #000;
  .custom-logo-link {
    height: 25px;
    width: 74%;
    max-width: 451px;
    display: inline-block;
    background: url('../images/logo-updated_white.png') no-repeat;
    background-size: 100% auto;
    img { display: none; }
    @media (max-width: $screen-xs-max) {
      width: 236px;
      height: 14px;
    }
  }
  .hero-content {
    position: relative;
    z-index: 20;
  }
  .blog-description {
    font-family: $headings-font-family;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.199em;
    margin-top: 18px;
    @media (max-width: $screen-xs-max) {
      font-size: 8px;
      margin-top: 0;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 20;
    height: 15px;
    width: 30px;
    background: url('../images/arrow-white.png') center no-repeat;
    bottom: 55px;
    left: 50%;
    margin-left: -15px;
  }

  &.has-feature-image {
    .slideshow,
    .feature-image {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      overflow: hidden;
    }

    .slideshow {
      .slick-track,
      .slick-list {
        height: 100%;
      }
      .slide {
        height: 100%;
        width: 100%;
        transition: opacity 750ms linear, transform 8s linear!important;
        transform: translate(15px, 5px) scale(1.1);
        background-size: cover;
        background-position: center;
        &.slick-active {
          transform: translate(-15px, -5px) scale(1.1);
        }
      }
    }
  }
}

.valign-middle {
  display: table;
  height: 100%;
  width: 100%;
  > div {
    display: table-cell;
    vertical-align: middle;
  }
}

@media (max-width: $screen-xs-max) {
  #cs-content .page-hero {
    .x-container.width { padding: 0!important; }
  }

  #about .x-text {
    font-size: 14px;
    text-align: center;
    padding-left: 17px;
    padding-right: 17px;
  }

  #story {
    .x-text {
      font-size: 13px;
      padding-left: 17px;
      padding-right: 17px;
    }
    .x-hr {
      width: 145px;
      height: 5px;
      margin-left: 17px;
    }
  }

  .friends-section {
    font-size: 13px;
    padding-bottom: 15px !important;
    + .friends-section { padding-top: 0 !important; }
    .x-hr {
      width: 190px!important;
    }
    .x-hr.thick {
      width: 100%!important;
      margin-bottom: 28px!important;
    }
    h2 { font-size: 28px; margin-top: 40px; }
    .friend-content-col-right,
    .friend-content-col-left {
      padding-left: 17px!important;
      padding-right: 17px!important;
    }
  }

  body.contact {
    .cs-content {
      font-size: 13px;
      text-align: center;
      h1 { font-size: 18px; }
      .x-text {
        padding-left: 17px;
        padding-right: 17px;
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  #cs-content .page-hero {
    .x-container.width { max-width: none!important; padding: 0!important; }
  }

  #story {
    .x-text {
      font-size: 14px;
    }
    .x-hr {
      width: 218px;
      height: 5px;
    }

    .story-content {
      .x-column {
        float: left;
        width: 48%;
        margin-right: 4%!important;
        &:last-child { margin-right: 0!important; }
      }
    }
  }

  .friends-section {
    font-size: 14px;
    padding-bottom: 15px !important;
    + .friends-section { padding-top: 0 !important; }

    .x-hr {
      width: 342px!important;
      margin-top: 60px!important;
    }
    .x-hr.thick {
      width: 306px!important;
      margin-top: 30px!important;
      margin-bottom: 80px!important;
    }
    h2 { font-size: 35px; margin-top: 0; }
  }

  body.contact {
    .cs-content {
      font-size: 14px;
      text-align: center;
      h1 { font-size: 22px; }
      .x-text {
        max-width: 517px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .friend-content-col-right { padding-left: 70px!important; }
  .friend-content-col-left { padding-right: 70px!important; }
}

.page-wrapper {
  overflow: hidden;
}

.page.about-page {
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 85px;
  @media (min-width: $screen-md) {
    img.size-full {
      max-width: 1400px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h1 {
    text-align: center;
  }
}

.error404 .main { margin-bottom: 60px; }

// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($line-height-computed / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($line-height-computed / 2);
  height: auto;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($line-height-computed / 2);
  }
  .alignright {
    float: right;
    margin-left: ($line-height-computed / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

/* WordPress Gallery
------------------------------------------ */
.gallery { @include make-row(); margin-top: 68px; margin-bottom: 38px; }
.gallery-item { margin: 0; float: left; padding: 0 15px 30px; }
.gallery-item img { }

.gallery-columns-1 .gallery-item { width: 100%; float: none; }
.gallery-columns-2 .gallery-item { width: 50% }
.gallery-columns-3 .gallery-item { width: 33.3333% }
.gallery-columns-4 .gallery-item { width: 33.3333% }
.gallery-columns-5 .gallery-item { width: 33.3333% }
.gallery-columns-6 .gallery-item { width: 33.3333% }
.gallery-columns-7 .gallery-item { width: 33.3333% }
.gallery-columns-8 .gallery-item { width: 33.3333% }
.gallery-columns-9 .gallery-item { width: 33.3333% }
@media (min-width: $screen-sm) {
  .gallery-columns-4 .gallery-item { width: 25% }
  .gallery-columns-5 .gallery-item { width: 25% }
  .gallery-columns-6 .gallery-item { width: 25% }
  .gallery-columns-7 .gallery-item { width: 25% }
  .gallery-columns-8 .gallery-item { width: 25% }
  .gallery-columns-9 .gallery-item { width: 25% }
}
@media (min-width: $screen-md) {
  .gallery-columns-4 .gallery-item { width: 25% }
  .gallery-columns-5 .gallery-item { width: 20% }
  .gallery-columns-6 .gallery-item { width: 16.6667% }
  .gallery-columns-7 .gallery-item { width: 14.2857% }
  .gallery-columns-8 .gallery-item { width: 12.5% }
  .gallery-columns-9 .gallery-item { width: 11.1111% }
}

.gallery-columns-2 .gallery-item:nth-child(2n+1),
.gallery-columns-3 .gallery-item:nth-child(3n+1),
.gallery-columns-4 .gallery-item:nth-child(4n+1),
.gallery-columns-5 .gallery-item:nth-child(5n+1),
.gallery-columns-6 .gallery-item:nth-child(6n+1),
.gallery-columns-7 .gallery-item:nth-child(7n+1),
.gallery-columns-8 .gallery-item:nth-child(8n+1),
.gallery-columns-9 .gallery-item:nth-child(9n+1) {
	clear: left;
}


.post {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
  @media (max-width: $screen-sm-max) {
    padding-left: 10px;
    padding-right: 10px;
  }
  .entry-title { font-size: 32px; margin-top: 20px; margin-bottom: 10px; }
  .entry-meta {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 13px;
  }

  h1 { font-size: 40px; }
  h2 { font-size: 27px; }
  h3 { font-size: 21px; }
  h4 { font-size: 18px; }
  h5 { font-size: 14px; }
  h6 { font-size: 12px; }
}

.post.excerpt {
  margin-bottom: 60px;
  header {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .entry-title {
    a {
      &:hover {
        color: $brand-primary;
      }
    }
  }
  .entry-summary { max-width: 570px; }
}

.sticky {}
.bypostauthor {}

body div.sharedaddy {
  margin-top: 40px;
  h3.sd-title {
    color: $brand-primary;
    font-size: 16px;
    line-height: 1.5;
    &:before { display: none; }
  }
  .sd-content ul {
    margin-left: -14px!important;
    margin-right: -14px!important;
    li {
      margin: 0 14px;
    }
  }
}

article.search {
  margin-bottom: 40px;
  margin-top: 26px;
  max-width: none;
  .entry-title {
    font-size: 20px;
    margin: 0 0 8px;
    a {
      color: $brand-primary;
    }
  }
  .entry-meta {
    font-size: 14px;
    a {
    }
  }
  .entry-summary {
    margin-top: 20px;
    p { margin: 0; }
  }
}


.row > .main { @include make-xs-column(12); }

#cs-content .x-container.width,
.container { width: 100%!important; max-width: $container-large-desktop; }
@media (min-width: $screen-lg) {
  #cs-content .x-container.width,
  .container { width: 90%!important; }
}
#cs-content .x-container.width { padding-left: $grid-gutter-width / 2!important; padding-right: $grid-gutter-width / 2!important; }

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
  #cs-content .x-container.max,
  #cs-content .x-container.width,
  .container {
    max-width: $container-desktop + 30px!important;
  }
}
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  #cs-content .x-container.max,
  #cs-content .x-container.width,
  .container {
    max-width: $container-tablet + 30px!important;
  }
}

#cs-content .x-column { width: 100%; margin-right: 30px!important; }
#cs-content .x-column.last, #cs-content .x-column:last-of-type { margin-right: 0!important; }

#cs-content .x-column.x-1-2,
#cs-content .x-column.x-1-3,
#cs-content .x-column.x-1-4,
#cs-content .x-column.x-1-5,
#cs-content .x-column.x-2-3,
#cs-content .x-column.x-3-4,
#cs-content .x-column.x-2-5,
#cs-content .x-column.x-3-5 { width: 100%; }

@media (min-width: $screen-md) {
  #cs-content .x-column.x-1-2 { width: calc(100% / 2 - 30px * (2 - 1) / 2); }
  #cs-content .x-column.x-1-3 { width: calc(100% / 3 - 30px * (3 - 1) / 3); }
  #cs-content .x-column.x-1-4 { width: calc(100% / 4 - 30px * (4 - 1) / 4); }
  #cs-content .x-column.x-1-5 { width: calc(100% / 5 - 30px * (5 - 1) / 5); }

  #cs-content .x-column.x-2-3 { width: calc(100% / 3 * 2 - 30px * (2 - 1) / 3); }

  #cs-content .x-column.x-3-4 { width: calc(100% / 4 * 3 - 30px * (4 - 3) / 4); }

  #cs-content .x-column.x-2-5 { width: calc(100% / 5 * 2 - 30px * (5 - 2) / 5); }
  #cs-content .x-column.x-3-5 { width: calc(100% / 5 * 3 - 30px * (5 - 3) / 5); }
}



@media (min-width: $screen-md) {
  .site-meta .menu-left { text-align: left; }
  .site-meta .menu-right { text-align: right; }
}
@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .site-meta .menu-left { text-align: right; }
  .site-meta .menu-right { text-align: left; }
  .site-meta .custom-logo { margin-bottom: 13px; }
}

@media (min-width: $screen-sm) {
  .nav-inline {
    margin-left: -12px;
    margin-right: -12px;
    > li {
      display: inline-block;
      margin: 0 12px;
      > a {
        padding: 0;
        display: inline;
        background: none!important;
      }
    }
  }
}

.before-footer {
  text-align: center;
  max-width: 1720px;
  margin-right: auto;
  margin-left: auto;
  font-family: Charter, times, serif;
  @media (max-width: $screen-xs-max) {
    font-size: 13px;
  }
  > .container {
    position: relative;
    padding-top: 107px;
    padding-bottom: 154px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 13px;
      opacity: 0.3;
      background-color: #7c878e;
      width: 470px;
      height: 1px;
    }
    @media (max-width: $screen-sm-max) {
      padding-top: 65px;
      padding-bottom: 75px;
      &:before {
        left: 50%;
        margin-left: -171px;
        width: 342px;
      }
    }
    @media (max-width: $screen-xs-max) {
      padding-top: 35px;
      padding-bottom: 70px;
      &:before {
        margin-left: -90px;
        width: 180px;
      }
    }
  }
  body.single-project & {
    > .container:before { display: none; }
  }

  .widget {
     max-width: 630px;
     margin: 0 auto 45px;
     &:last-child { margin-bottom: 0; }
     p:last-child { margin-bottom: 0; }
     .btn {
       margin-top: 30px;
       @media (max-width: $screen-xs-max) {
         margin-top: 15px;
       }
     }
   }
  .widget-title { margin-bottom: 20px; }

  @media (max-width: $screen-sm-max) {
    > .container {
      max-width: 528px!important;
      font-size: 14px;
    }
  }
  @media (max-width: $screen-xs-max) {
    .textwidget {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.site-meta {
  > .container {
    padding-top: 57px;
    padding-bottom: 82px;
  }
  .custom-logo-link {
    max-width: 207px;
    display: block;
    margin: 0 auto;
  }
  .nav > li > a {
    color: #868686;
    font-family: $headings-font-family;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.199em;
    &:hover {
      color: #000;
    }
  }
  @media (max-width: $screen-xs-max) {
    text-align: center;
    .custom-logo-link { margin-bottom: 20px; }
    .nav > li > a { padding-top: 7px; padding-bottom: 7px; }
  }
}

// default variation
.site-meta {
   > .container {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 13px;
      opacity: 0.3;
      background-color: #7c878e;
      width: 470px;
      height: 1px;
      @media (max-width: $screen-sm-max) {
        right: 50%;
        margin-right: -171px;
        width: 342px;
      }
      @media (max-width: $screen-xs-max) {
        margin-right: -90px;
        width: 180px;
        // center on single
        body.single-project & {
          right: 50%;
          margin-right: -90px;
        }
      }
    }
  }
}


.w-animate {
  transition: all 1.5s ease;
}

.w-animated {

}

.w-fade-in-down {
  opacity: 0;
  transform: translateY(-25px);
  &.w-animated {
    opacity: 1;
    transform: translateY(0);
  }
}

.w-fade-in-up {
  opacity: 0;
  transform: translateY(25px);
  &.w-animated {
    opacity: 1;
    transform: translateY(0);
  }
}

.w-fade-in-right {
  opacity: 0;
  transform: translateX(25px);
  &.w-animated {
    opacity: 1;
    transform: translateX(0);
  }
}

.w-fade-in-left {
  opacity: 0;
  transform: translateX(-25px);
  &.w-animated {
    opacity: 1;
    transform: translateX(0);
  }
}

.cs-editor-active {
  .w-animate {
    opacity: 1;
    transform: translate(0);
  }
}

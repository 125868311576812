
.draggable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.project {
  @media (max-width: $screen-xs-max) {
    font-size: 13px;
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    font-size: 15px;
  }
  .slick-slider {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 35px;
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      text-indent: 100%;
      overflow: hidden;
      padding: 0;
      white-space: nowrap;
      border: none;
      width: 15%;
      z-index: 50;
      background: none;
      outline: none!important;
      &.slick-next {
        right: 0;
      }
      &.slick-prev {
        left: 0;
      }
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10px;
      .slick-slide img {
        max-height: 380px;
      }
    }
    .slick-slide {
      padding-left: 17.5px;
      padding-right: 17.5px;
      &:focus { outline: none; }
    }
  }

  .container {
    max-width: 900px;
    margin-bottom: 92px;
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      margin-bottom: 50px;
    }
  }
  .entry-title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 36px;
    @media (max-width: $screen-xs-max) {
      font-size: 25px;
      margin-bottom: 20px;
    }
  }
  .entry-content {
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      margin-bottom: 40px;
    }
    &.visible-xs {
      margin-top: 40px;
    }
  }

  .mobile-gallery img { margin-bottom: 16px; }

  .entry-meta {
    list-style: none;
    color: #1d1d1b;
    font-family: $headings-font-family;
    font-size: 10px;
    font-weight: 400;
    padding: 6px 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    dd {
      margin-bottom: 20px;
    }
  }
}

.related.projects {
  max-width: 1720px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f2f2f2;
  padding-bottom: 100px;
  padding-top: 74px;
  @media (max-width: $screen-sm-max) {
    padding-top: 50px;
    padding-bottom: 51px;
  }
  > .container > h2 {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.199em;
    text-align: center;
    margin-bottom: 0;
    margin-bottom: 70px;
    @media (max-width: $screen-sm-max) {
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 30px;
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      margin-bottom: 40px;
    }
  }
}

.project-grid {
  margin-left: -29px;
  margin-right: -29px;
  @media (max-width: $screen-sm-max) {
    margin-left: -15px;
    margin-right: -15px;
  }
  @media (max-width: $screen-xs-max) {
    margin-left: -9px;
    margin-right: -9px;
  }
  .project { text-align: center; }
  .entry-meta { color: #fff; }
  .project-content {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    bottom: 0;
    display: block;
    color: #fff;
    opacity: 0;
    transition: all 0.4s;
    &:hover {
      opacity: 1;
      .project-title,
      .entry-meta {
        opacity: 1;
        transform: translateY(0);
      }
    }
    background: rgba(#262728,0.8);
    > div {
      display: table;
      height: 100%;
      width: 100%;
      > .project-title {
        display: table-cell;
        vertical-align: middle;
      }
    }
    a { color: #fff; }
    .project-title {
      color: #f7f7f7;
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
      opacity: 0;
      transition: all 0.3s ease 0.1s;
      transform: translateY(-10px);
      @media (max-width: $screen-sm-max) {
        font-size: 25px;
      }
      @media (max-width: $screen-xs-max) {
        font-size: 20px;
      }
    }
    .entry-meta {
      position: absolute;
      bottom: 20px;
      padding: 0;
      left: 0;
      right: 0;
      opacity: 0;
      list-style: none;
      transition: all 0.3s ease 0.15s;
      transform: translateY(5px);
      @media (max-width: $screen-sm-max) {
        li { display: block; }
        li:before { display: none; }
      }
    }
  }
  .feature-image {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .project-col {
    position: relative;
    @include make-xs-column(6);
    @include make-sm-column(4);
    padding-left: 29px!important;
    padding-right: 29px!important;
    margin-bottom: 47px;
    > .project { position: relative; }
    &:nth-child(7n + 1) {
      @include make-xs-column(12);
      @include make-sm-column(8);
    }
    &:nth-child(7n + 7) {
      @include make-xs-column(12);
      @include make-sm-column(8);
    }
    @media (max-width: $screen-sm-max) {
      margin-bottom: 25px;
      padding-left: 15px!important;
      padding-right: 15px!important;
    }
    @media (max-width: $screen-xs-max) {
      margin-bottom: 18px;
      padding-left: 9px!important;
      padding-right: 9px!important;
      &:nth-child(7n - 1) {
        @include make-xs-column(12);
      }
    }
  }
}

.project-col {
  > .project {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s;
  }
  &.is-inview > .project {
    opacity: 1;
    transform: translateY(0);
  }
}

.comment-list {
  @include list-unstyled;
}
.comment-list ol {
  list-style: none;
}

.comments {
  padding-top: 60px;
}

.comments h2 {
  margin-bottom: 50px;
  color: #4288a9;
  font-family: $font-family-sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  span {
    float: right;
    color: #a3b1bb;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
    font-style: italic;
    position: relative;
    top: 8px;
  }
}
p.comment-notes,
p.logged-in-as { font-size: 14px; }

.comment-awaiting-moderation {
	display: block;
	@extend .alert;
	padding: 4px 8px;
	margin: 0;
	@extend .alert-warning;
}

.comment-list {
	list-style: none;
	margin-left: 0;
	padding: 6px 100px 0 0;
  font-size: 17px;
  p { margin-bottom: 10px!important; }
	.comment {
		padding-left: 100px;
		margin-bottom: 40px;
    position: relative;
		.avatar {
			left: 0;
      top: 0;
      width: 68px;
      margin-top: 4px;
      border-radius: 50%;
      position: absolute;
		}
	}

	.children {
		list-style: none;
		margin-left: -32px;
		padding: 0;
		margin-top: 30px;
		.comment {
			padding-left: 85px;
			.avatar {
        margin-top: 4px;
        width: 54px;
			}

      .fn:after {
        content: '\e801';
        font-family: fontello;
        opacity: 0.5;
        margin-left: 5px;
        font-size: 12px;
      }
		}
	}

  .comment-metadata { line-height: 1; margin-bottom: 0; }
  .comment-metadata a,
  .reply a {
    color: $gray;
    font-size: 12px;
  }

  .reply {
    margin-top: 0;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
  }

	.comment-text {
		font-size: 15px;
    padding-top: 5px;
		p { margin-bottom: 6px; }
		@media (max-width: $screen-xs-max) {
			font-size: 13px;
		}
	}

	.comment-content {
	}

  p.meta { margin-bottom: 8px!important; }

	.comment-meta {
		margin-bottom: 4px;
    .comment-author,
    .comment-metadata {
      display: inline-block;
      vertical-align: middle;
    }
    .comment-metadata {
      color: #a3b1bb;
      font-family: $font-family-sans-serif;
      font-size: 13px;
      a {
        color: #a3b1bb;
      }
    }

		.fn {
			font-weight: 700;
			display: inline-block;
			font-size: 15px;
			margin-right: 24px;
		}
    .says { display: none; }
		.time {
			font-size: 13px;
			display: inline-block;
		}
	}

	.comment-actions {
		font-size: 11px;
		margin-top: 6px;
		text-transform: uppercase;
		> a {
			&:after { content: '-'; margin: 0 0 0 8px; }
			&:last-child:after { content: ''; }
		}
	}
}

.comment-list .bypostauthor .comment-meta {
	.avatar {
		box-sizing: content-box;
	}
	.author-name {
		&:after {
			content: '(Post author)';
			font-weight: normal;
			margin-left: 8px;
			font-size: 12px;
			display: inline-block;
			color: $text-color;
		}
	}
}

.comment-content a {
	word-wrap: break-word;
}

.comment-reply-title {
  color: #c55469;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: lowercase;
}

#respond {
	clear: both;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
	.form-submit {
		text-align: right;
		margin-bottom: 20px;
    margin-right: 15px;
	}
}

@media (max-width: $screen-xs-max) {
  .comment-list {
    padding-left: 0;
    padding-right: 0;
    .comment {
      padding-left: 0;
      .avatar {
        position: static;
        margin-right: 10px;
      }
    }
    .children {
      margin-left: 0;
      margin-top: 10px;
      .comment { padding-left: 20px; }
    }
  }

  #respond {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


a { transition: all 0.2s; }
img { max-width: 100%; height: auto; }
body { overflow-y: scroll; position: relative; -webkit-font-smoothing: antialiased; }

// page title about us
h1, .h1 { font-family: $font-family-serif; font-size: $font-size-h1; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: none; letter-spacing: 0; }
// team member name single, about page 2nd level heading "friend"
h2, .h2 { font-family: $headings-font-family; font-size: $font-size-h2; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: none; letter-spacing: 0; }
// project title single, about page 3rd level heading
h3, .h3 { font-family: $headings-font-family; font-size: $font-size-h3; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: none; letter-spacing: 0; }
// team member name on grid, project title grid
h4, .h4 { font-family: $headings-font-family; font-size: $font-size-h4; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: uppercase; letter-spacing: 0; }
// contact us in prefooter, contact us title
h5, .h5 { font-family: $headings-font-family; font-size: $font-size-h5; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: uppercase; letter-spacing: 0.199em; }
@media (max-width: $screen-sm-max) {
  h1, .h1 { font-size: 60px; }
  h3, .h3 { font-size: 30px; }
}
@media (max-width: $screen-xs-max) {
  h1, .h1 { font-size: 45px; }
  h3, .h3 { font-size: 25px; text-align: center; }
  h5, .h5 { font-size: 20px; }
}
// project "more solid timber"
h6, .h6 { font-family: $headings-font-family; font-size: $font-size-h6; font-weight: $headings-font-weight; line-height: $headings-line-height; text-transform: uppercase; letter-spacing: 0.199em; }

// project title single, project title grid
.charter { font-family: 'Charter', $font-family-serif; }

.uppercase { text-transform: uppercase; }
.letter-spacing { letter-spacing: 0.119em; }

#cs-content .x-blockquote {
  font-family: $font-family-serif;
  font-size: $font-size-large;
  font-style: italic;
  line-height: 1.8;
  border: none;
  max-width: 864px;
  padding: 0;
  text-align: center;
  margin: 128px auto;
  &:before {
    content: '\201C';
    font-family: 'charter', times, serif;
    display: block;
    font-size: 50px;
    font-weight: 700;
    line-height: 32px;
  }
  @media (max-width: $screen-sm-max) {
    font-size: 20px;
    max-width: 540px;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 16px;
  }
}

#cs-content {
  .h-custom-headline { letter-spacing: 0; }
  h3.h-custom-headline, .h3.h-custom-headline { text-transform: uppercase; letter-spacing: 0.199em; }
  h4.h-custom-headline, .h4.h-custom-headline { text-transform: uppercase; letter-spacing: 0.199em; }
  h5.h-custom-headline, .h5.h-custom-headline { text-transform: uppercase; letter-spacing: 0.199em; }
}

body #cs-content .x-img { margin-bottom : 0; }

.page-header {
  padding: 0;
  margin: 0;
  border: none;
}

#nprogress .bar {
  background: #aac5d2;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px #aac5d2, 0 0 5px #aac5d2;
}


.single.team {
  overflow: hidden;
  @media (max-width: $screen-sm-max) {
    font-size: 14px;
  }
  &:after {
    content: '';
    display: block;
    width: 470px;
    height: 1px;
    background-color: #7c878e;
    opacity: 0.3;
    margin: 170px auto 130px ;
    clear: both;
  }
  @media (min-width: $screen-md) {
    .feature-image {
      float: right;
      border-left: 130px solid #fff;
      margin-top: 38px;
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .feature-image {
      float: right;
      width: 50%;
      padding-bottom: 30px;
      padding-left: 30px;
      background: #fff;
      margin-top: 64px;
    }
  }

  .entry-title {
    font-size: 50px;
    font-weight: 700;
    border-bottom: 5px solid #000;
    padding-bottom: 10px;
    margin-bottom: 44px;
    margin-top: 0;
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  .entry-meta {
    color: #868686;
    font-family: $headings-font-family;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.199em;
    margin-top: 40px;
    a {
      color: #868686;
    }
  }
  @media (max-width: $screen-xs-max) {
    &:after {
      width: 180px;
      height: 1px;
      margin-top: 70px;
      margin-bottom: 70px;
    }
    .entry-title {
      font-size: 30px;
      text-align: center;
      border: none;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 50px;
        height: 4px;
        background: #000;
        margin-left: -25px;
      }
    }
    .feature-image { margin-bottom: 50px; }
    .entry-content {
      font-size: 14px;
      padding-left: 14px;
      padding-right: 14px;
    }
    .entry-meta {
      font-size: 9px;
    }
  }
  @media (max-width: $screen-sm-max) {
    &:after {
      width: 210px;
      height: 1px;
      margin-top: 70px;
      margin-bottom: 70px;
    }
    .entry-meta {
      text-align: right;
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

.excerpt.team {
  text-align: center;
  font-size: 15px;
  line-height: 1.66667;
  @media (max-width: $screen-sm-max) {
    font-size: 14px;
  }
  .entry-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 16px;
    @media (max-width: $screen-sm-max) {
      font-size: 25px;
    }
  }
  .read-more {
    display: block;
    font-family: $headings-font-family;
    font-size: 10px;
    font-weight: 400;
    line-height: 3;
    text-transform: uppercase;
    letter-spacing: 0.199em;
    margin-top: 14px;
  }
}

.team-grid {
  @media (min-width: $screen-md) {
    margin-left: -29px;
    margin-right: -29px;
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    margin-left: 0;
    margin-right: 0;
  }
  margin-bottom: 60px;
  .feature-image {
  }
  .team-col {
    @include make-sm-column(6);
    @include make-md-column(3);
    margin-bottom: 47px;
    @media (min-width: $screen-md) {
      padding-left: 29px;
      padding-right: 29px;
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      .team {
        padding-left: 12px;
        padding-right: 12px;
      }
      .entry-content {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  hr {
    margin: 0 auto 100px;
    display: block;
    width: 470px;
    height: 1px;
    background-color: #7c878e;
    opacity: 0.3;
    clear: both;
    &:last-child { display: none; }
    @media (min-width: $screen-md) {
      &:nth-of-type(odd) { display: none; }
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      width: 210px;
      margin-bottom: 70px;
    }
  }
  @media (max-width: $screen-xs-max) {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
    .feature-image {
      margin-bottom: 20px;
    }
    .team-col {
      padding-left: 15px;
      padding-right: 15px;
      &:nth-child(3),
      &:nth-child(4) { display: none; }
    }
    hr { display: none; }
  }
}


.team-col {
  > .team {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s;
  }
  &.is-inview > .team {
    opacity: 1;
    transform: translateY(0);
  }
}



.banner {
  position: relative;
  min-height: 80px;
  z-index: 1010;
  @media (max-width: $screen-xs-max) {
    min-height: 72px;
  }
}
.navbar {
  position: relative;
  border: none!important;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  transition: min-height 0.3s;
  min-height: 70px;
  .navbar-header .navbar-page-title { font-size: 14px; }
  &.navbar-inverse {
    z-index: 990;
  }
  @media (min-width: $screen-sm) {
    &.affix {
      position: fixed;
      background: #fff!important;
      min-height: 70px;
      .mobile-filter { display: none!important; }
    }
  }
  .custom-logo-link {
    display: inline-block;
    vertical-align: middle;
    height: 140px;
    max-width: 205px;
    padding: 20px 0;
    margin-left: 15px;
    margin-right: 15px;
    width: 326px;
    height: 70px;
    transition: all 0.3s;
    background-size: 296px;
    background-size: 178px;
    body.collapse-active & {
      display: inline-block;
      background-image: url('../images/logo-white.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      img { display: none; }
    }
  }
  .navbar-toggle {
    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
  }
}

.collapse-active {
  .navbar-subnav { display: none!important; }
}

.navbar-header {
  .navbar-page-title {
    font-family: $headings-font-family;
    font-size: 20px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    vertical-align: middle;
    transition: all 0.3s;
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      font-size: 12px;
    }
    &:before {
      content: '|';
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
  .navbar-subnav {
    ul {
      margin-right: 15px;
      > li {
        margin: 0;
        > a {
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 0.2em;
          font-family: $headings-font-family;
          opacity: 0.7;
          padding: 6px 15px;
          display: block;
          &:hover {
            opacity: 1;
          }

          &.view-all {
            &:before {
              content: '\203a';
              position: relative;
              left: -15px;
              top: -1px;
            }
          }
        }
      }
      @media (max-width: $screen-sm-max) {
        margin-right: 0;
        > li {
          margin: 0;
          > a {
            font-size: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .navbar-subnav .filter-button-group > li { margin-left: 0; margin-right: 0; }
  .navbar-subnav .filter-button-group > li > a { transition: background 0.2s; opacity: 1; }
  .navbar-subnav .filter-button-group > li.active > a,
  .navbar-subnav .filter-button-group > li > a:hover { background: #f2f2f2!important; }
}

.site-header {
  background: none;
  .navbar-collapse {
    top: 100%;
    width: auto;
    margin-top: -50px;
    box-shadow: none;
    border: none;
    position: absolute;
  }

  .navbar-right {
    float: right;
    margin-top: 18px;
    margin-bottom: 18px;
    transition: margin 0.3;
    .navbar-toggle {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (max-width: $screen-sm-max) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .navbar-toggle {
    display: inline-block;
    .icon-bar {
      transition: all 0.2s;
      width: 29px;
      margin-left: auto;
      margin-right: 0;
      border-radius: 0;
      body.collapse-active & {
        width: 29px;
        background-color: #fff;
        &:nth-child(1),
        &:nth-child(3) { width: 22px; }
      }
      @media (max-width: $screen-xs-max) {
        width: 18px;
        & + .icon-bar { margin-top: 3px; }
        body.collapse-active & {
          width: 21px;
          &:nth-child(1),
          &:nth-child(3) { width: 16px; }
        }
      }
    }
  }
}

body {
  position: relative;
  @media screen and (max-width: 600px) {
    position: static;
  }
  &:after {
    content: '';
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(#000, 0.8);
    animation: fadeInFromNone 0.4s ease-out;
  }
  &.collapse-active {
    // animation: fadeOutFromBlock 0.3s ease-out 0.3s;
    .mobile-filter { visibility: hidden; }
    &:after {
      display: block;
      opacity: 1;
    }
  }
}
.site-header.navbar-inverse {
  background: none!important;
  z-index: 1010;
  .custom-logo-link { transition: none!important; }
}

@keyframes fadeInFromNone {
  0%   { display: none;  opacity: 0; }
  1%   { display: block; opacity: 0; }
  100% { display: block; opacity: 1; }
}

@keyframes fadeOutFromBlock {
  0%   { display: block;  opacity: 1; }
  99%  { display: block; opacity: 0; }
  100% { display: none; opacity: 0; }
}

.site-header .navbar-collapse.collapsing { height: 0; transition: height 0.01s; }
.site-header > .container { position: relative; }
.site-header .navbar-collapse {
  padding: 0;
  margin: 0;
  position: absolute;
  left: 15px;
  right: 15px;
  .navbar-nav {
    margin-top: 73.48px;
    margin-left: 0;
    margin-right: 0;
    > li {
      position: relative;
      margin-bottom: 60px;
      > ul {
        position: absolute;
        bottom: 4px;
        left: 240px;
        text-align: right;
        margin-left: 4px;
        /* &:before {
          content: '';
          display: block;
          height: 1px;
          width: 30%;
          background-color: #474747;
          position: absolute;
          bottom: 9px;
          right: 0;
          left: 240px;
        } */
        > li {
          display: inline-block;
          margin: 0 4px;
          &:after { color: #fff; content: '.'; margin-left: 13px; }
          &:last-child:after { display: none; }
          > a {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.1em;
            font-family: $headings-font-family;
            &:hover { color: #ccc; }
          }
        }
      }
      > a {
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-size: 27px;
        font-family: $headings-font-family;
        padding-left: 0;
        padding-right: 15px;
        display: inline-block;
        position: relative;
        z-index: 1;
        transition: all 0.3s;
      }

      &.nav-small { display: none; }
    }
  }
}
@media (max-width: $screen-xs-max) {
  .site-header .navbar-collapse {
    text-align: center;
    .navbar-nav {
      margin-left: 0;
      margin-right: 0;
      margin-top: 30.48px;
      > li {
        margin-bottom: 10px;
        > ul { display: none; }
        > a {
          font-size: 18px;
          padding-left: 0;
          padding-right: 0;
          background: none!important;
        }

        &.nav-small {
          margin-bottom: 3px;
          display: block;
          > a {
            font-size: 11px;
            padding: 6px 6px 0;
            margin-top: 30px;
          }
        }
        &.nav-small:last-child > a {
          margin-top: 0;
        }
      }
    }
  }
}

.filter-toggle {
  display: block;
  text-align: right;
  position: relative;
  text-transform: uppercase;
  font-family: $headings-font-family;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.199em;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: url('../images/down-arrow-small.png') no-repeat;
    height: 6px;
    width: 9px;
    right: 50px;
    top: 50%;
    margin-top: -3px;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: auto;
    left: 0;
    right: 80px;
    top: 50%;
    background: #d7dbdd;
  }
  @media (min-width: $screen-sm) {
  }
}

.mobile-filter {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  clear: both;
  margin-bottom: 10px;
  ul {
    list-style: none;
    margin: 0!important;
    padding: 15px 0;
    text-align: center;
    background: #fff;
  }
  @media (min-width: $screen-sm) {
    margin-bottom: 0;
    &.visible-sm {
      margin-bottom: 0;
      > .filter-toggle { display: none; }
    }
  }
}

.site-header-1 { position: relative; }
